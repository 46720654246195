body{
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  background-image: url(./Assets/Images/DockMac-light.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
